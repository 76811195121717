import React from "react";
import Layout from "../layouts/index";
import { Link } from "gatsby";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-404">
        <div className="intro intro-small">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>404 - Not Found</h1>
                <p>You just hit a page that doesn't exist...</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-8 pt-md-4 pb-4">
          <div className="row justify-content-start">
            <div className="col-12">
              <div className="card about-teaser">
                <div className="card-content">
                  <h2>
                    We are Manufacturers of Optical Goods, Barrel-Drum Materials,
                    Grinding & Polishing Materials.
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <Link className="button button-primary mt-2" to="/">
                Take Me Back
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
